import { Text as Paragraph } from '../../Typography';
import { Link } from '../../Link';
import { LinksService } from 'service/links/linksService';
import { FooterNavType, Links } from '../types';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';

interface IListTemplate {
  onClickHandler: (target: string, linksTitle?: string) => void;
  flinks: FooterNavType;
  linksTitle: string;
}

const ListTemplate = ({ flinks, linksTitle, onClickHandler }: IListTemplate) => {
  const { pathTranslations } = useGlobalContext();
  return flinks?.links?.map((fNavLink: Links) => (
    <li
      className="mb-5"
      key={fNavLink.id}
      onClick={() => onClickHandler(fNavLink?.displayName || '', linksTitle)}
    >
      <Paragraph tag="p" type="md">
        <Link
          href={
            LinksService.getInternalLinks({ item: fNavLink, pathTranslations }) ||
            fNavLink?.url ||
            '/'
          }
          target={fNavLink.openInNewTab ? '_blank' : '_self'}
        >
          {fNavLink?.displayName}
        </Link>
      </Paragraph>
    </li>
  ));
};

export default ListTemplate;
