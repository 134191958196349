import { RichText } from '@graphcms/rich-text-react-renderer';
import { WavyCircleIcon } from '@icons/WavyCircle';
import clsx from 'clsx';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  Accordion as ReactAccordion,
} from 'react-accessible-accordion';
import { Title } from '../Typography';

export interface IAccordionItemProps {
  id?: string;
  title?: any;
  peek?: any;
  body?: any;
  collapsed?: boolean | null;
  stringTitle?: string;
  trackingEvent?: (data: any, position: number) => void;
}

export type ForceTitleSpaceMode = 'none' | 'both' | 'mobile';

export interface IAccordionProps {
  name: string;
  data?: IAccordionItemProps[];
  collapsed?: boolean | null;
  $isNumbered?: boolean | null;
  $forceTitleSpace?: ForceTitleSpaceMode;
  page?: string;
  titleTogglePos?: 'top' | 'center';
  trackingEvent?: (title: string, position: number) => void;
}

export const Accordion = ({
  name,
  data,
  $isNumbered = false,
  collapsed = false,
  $forceTitleSpace = 'none',
  page = '',
  trackingEvent,
  titleTogglePos = 'center',
  ...props
}: IAccordionProps) => {
  const preExpanded = [];

  if (!collapsed && data) {
    for (let i = 0; i < data.length; i += 1) {
      if (!data[i].collapsed) {
        preExpanded.push(`${name}-${i}`);
      }
    }
  }
  const onclickHandler = (title: string, position: number) => {
    if (trackingEvent && page === 'product') {
      trackingEvent(title, position);
    }
  };
  return (
    <ReactAccordion
      className={`title-toggle-pos-${titleTogglePos} border-b border-[#bbc4ba]`}
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={preExpanded}
      {...props}
    >
      {data
        ?.filter((item) => item.body)
        .map((item, index) => (
          <AccordionItem
            uuid={`${name}-${index}`}
            key={`${name}-${item.id ?? index}`}
            className="border-t border-[#bbc4ba] first:border-t-0"
          >
            <AccordionItemHeading
              onClick={() => onclickHandler(item.stringTitle || '', index + 1)}
              data-index={index}
            >
              <AccordionItemButton
                data-print="hide-before-content"
                className={clsx(
                  'flex cursor-pointer items-center py-[21px] pr-[25px] pl-0 w-full text-left border-0 relative group before:content-["+"] before:text-3xl before:block before:absolute before:right-2.5 before:aria-expanded:content-["-"] before:aria-selected:content-["-"]',
                  titleTogglePos === 'center' && 'before:top-1/2 before:-translate-y-1/2',
                  titleTogglePos === 'top' && 'before:top-2.5 ',
                )}
              >
                {$isNumbered && (
                  <div
                    className="relative mr-3 w-11 h-11 md:mr-10"
                    data-testId={`accordion-number-container-${item.id}`}
                  >
                    <WavyCircleIcon />
                    <Title
                      className="absolute -inset-0 mt-px flex items-center justify-center"
                      tag="div"
                      type="xxxs"
                    >
                      {index + 1}
                    </Title>
                  </div>
                )}
                <div className="w-3/4 md:w-4/5 pr-4 md:pr-0">
                  {item.title}
                  {item?.peek && (
                    <div className="peek-text group-aria-expanded:hidden">{item.peek}</div>
                  )}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              className={clsx(
                'animate-fadeIn',
                !item?.title && $forceTitleSpace && $forceTitleSpace !== 'both'
                  ? $forceTitleSpace === 'none'
                    ? 'sm:-mt-12 md:-mt-16'
                    : 'md:-mt-14'
                  : '',
              )}
              data-print="text-visible"
            >
              {item?.body?.raw ? (
                <div className="mb-2.5 md:mb-5">
                  <RichText content={item?.body?.raw} />
                </div>
              ) : (
                item?.body
              )}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
    </ReactAccordion>
  );
};
