import { ILanguageItem } from '../types';
import React from 'react';

const LanguageItem = ({
  language,
  updateLanguage,
}: {
  language: ILanguageItem;
  updateLanguage: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <button
      data-redirect={language?.url}
      data-countrylink={language?.url}
      id={language.id}
      onClick={updateLanguage}
      className="hover:text-white block py-18 px-5 w-full font-neutral tracking-wider text-sm text-darkgray text-left"
    >
      {language?.displayName}
    </button>
  );
};

export default LanguageItem;
