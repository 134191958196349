import { ImagesService } from 'service/images/imagesService';
import { IFooterImage } from '../types';
import NextImage from '../../NextImage';

const FooterImage = ({ image }: IFooterImage) => (
  <div className="footer-image-component">
    <div className="curve-mask-inverted inverted w-full">
      <div className=" max-h-[792px] w-full overflow-hidden col-start-1 row-start-1">
        <NextImage
          sources={ImagesService.getPictureSources(image?.asset?.[0])}
          className="object-cover h-full relative w-full"
          alt=""
        />
      </div>
    </div>
  </div>
);

export default FooterImage;
