import NextImage from '../../NextImage';
import React from 'react';

const SelectedItem = ({
  selectedItem,
  selectedImage,
}: {
  selectedItem?: string;
  selectedImage?: string;
}) => {
  return selectedImage ? (
    <div className="flex items-center">
      <NextImage
        src={selectedImage}
        alt="Flag image"
        className="align-top mr-6"
        width={18}
        height={18}
      />
      <p>{selectedItem}</p>
    </div>
  ) : (
    <p>{selectedItem}</p>
  );
};

export default SelectedItem;
