import { GridItem, GridRow } from '../../Grid';
import { Text as Paragraph, Title } from '../../Typography';
import { Link, StyledLink } from '../../Link';
import { FooterNavigation } from '../../../types/Footer';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { LinksService } from 'service/links/linksService';
import clsx from 'clsx';

interface ILegal {
  legal: FooterNavigation;
  privacySettingsTitle?: string;
  textColorToken?: string;
}

const Legal = ({ legal, privacySettingsTitle, textColorToken }: ILegal) => {
  const { pathTranslations } = useGlobalContext();
  return (
    <GridRow columns={16}>
      <GridItem colSpan={16}>
        <div
          className={clsx('border-t md:pt-20 md:mt-20 sm:pt-10 sm:mt-5 md:flex align-top', {
            'border-cake': !textColorToken,
          })}
        >
          {legal && (
            <>
              <Title
                tag="p"
                type="xxxs"
                className={clsx('md:w-1/5 sm:w-full sm:mb-2', {
                  'text-gray-500': !textColorToken,
                })}
              >
                {legal.cmsTitle}
              </Title>
              <div className="md:w-4/5 sm:w-full md:text-right sm:text-left">
                <ul className="m-0 p-0">
                  <li className="md:inline-block md:w-auto sm:w-full mr-xsmall last:m-0">
                    <Paragraph tag="p" type="md">
                      <StyledLink>
                        <a href="#" onClick={() => (window as any)?.UC_UI?.showSecondLayer()}>
                          {privacySettingsTitle || 'Privacy Settings'}
                        </a>
                      </StyledLink>
                    </Paragraph>
                  </li>
                  {legal?.links?.map((legallinks: any) => (
                    <li
                      className="md:inline-block md:w-auto sm:w-full mr-xsmall last:m-0"
                      key={`legal-${legallinks.id}`}
                    >
                      <Paragraph tag="p" type="md">
                        <Link
                          href={
                            LinksService.getInternalLinks({ item: legallinks, pathTranslations }) ||
                            legallinks?.url ||
                            '/'
                          }
                          target={legallinks.openInNewTab ? '_blank' : '_self'}
                        >
                          {legallinks.displayName}
                        </Link>
                      </Paragraph>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </GridItem>
    </GridRow>
  );
};

export default Legal;
