import NextImage from '../../NextImage';
import { IAppStoreComponent, IAppStoreLink } from '../../../types/Footer';
import { NextLink } from '../../NextLink';

interface IAppStoreLinkProps {
  handleAppStore: (target: string) => void;
  appStoreLink?: IAppStoreLink;
  appStoreComponent?: IAppStoreComponent;
  device: 'ios' | 'android';
}

const AppStoreLink = ({
  appStoreComponent,
  appStoreLink,
  handleAppStore,
  device,
}: IAppStoreLinkProps) => {
  if (!appStoreComponent?.asset?.[0]?.url) {
    return null;
  }

  return (
    <NextLink
      className="rounded h-9 w-28 mr-2 bg-black inline-block"
      href={appStoreLink?.url ?? ''}
      onClick={() => handleAppStore(device)}
    >
      <NextImage
        src={appStoreComponent?.asset?.[0]?.url}
        alt={appStoreComponent?.cmsAssetTitle ?? ''}
        mimeType={String(appStoreComponent?.asset?.[0]?.mimeType)}
        className="w-full h-full"
        width={136}
        height={40}
      />
    </NextLink>
  );
};

export default AppStoreLink;
