import { Text as Paragraph } from '../../Typography';
import NextImage from '../../NextImage';
import { Link } from '../../Link';
import { SocialMedia } from '../types';
import clsx from 'clsx';

interface ISocialMediaLinks {
  socialMedia?: SocialMedia[];
  onClickHandler: (target: string, linksTitle?: string) => void;
  backgroundColorToken?: string;
}

const SocialMediaLinks = ({
  onClickHandler,
  socialMedia,
  backgroundColorToken,
}: ISocialMediaLinks) => {
  if (!socialMedia) {
    return null;
  }
  return (
    <>
      {socialMedia.map((social: SocialMedia) => {
        const socialMPosition =
          (social?.socialSharePosition && social.socialSharePosition.indexOf('Footer') > -1) ||
          false;
        if (socialMPosition) {
          return (
            <li
              className="mb-5 list-none"
              key={social.id}
              onClick={() => onClickHandler(social?.name || '', 'social')}
            >
              <Paragraph tag="p" type="md" className="flex items-center">
                {social?.assetsComponents?.[0]?.asset?.[0]?.url && (
                  <span
                    className={clsx('rounded-full inline-flex justify-center items-center mr-2 ', {
                      'invert-[100%]': backgroundColorToken,
                    })}
                  >
                    <NextImage
                      src={social?.assetsComponents?.[0]?.asset?.[0]?.url || ''}
                      mimeType={String(social?.assetsComponents?.[0]?.asset?.[0]?.mimeType)}
                      alt={social?.name}
                      width={18}
                      height={18}
                      className="inline-block"
                    />
                  </span>
                )}
                <Link href={social?.url || ''} target="_blank">
                  {social?.name}
                </Link>
              </Paragraph>
            </li>
          );
        }
        return false;
      })}
    </>
  );
};

export default SocialMediaLinks;
