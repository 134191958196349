import { Text as Paragraph } from '../../Typography';
import { SelectBoxArrowUp } from '@icons/SelectBoxArrowUp';
import { SelectBoxArrowDown } from '@icons/SelectBoxArrowDown';
import { ICountryItem, ILanguageItem } from '../types';
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Ref } from '../../Cards/components/ContentWrapper';
import SelectedItem from './SelectedItem';
import CountryItem from './CountryItem';
import LanguageItem from './LanguageItem';

interface IDropDownProps {
  showDropDown: boolean;
  selectedItem?: string;
  selectedImage?: string;
  onButtonClick: () => void;
  label?: string;
  hidden: boolean;
  withImage?: boolean;
  data?: ILanguageItem[] | ICountryItem[];
  update: (event: React.MouseEvent<HTMLButtonElement>) => void;
  textColorToken?: string;
  bgColorToken?: string;
}

const DropDown = forwardRef<Ref, IDropDownProps>(
  (
    {
      update,
      label,
      hidden,
      onButtonClick,
      selectedItem,
      selectedImage,
      showDropDown,
      data,
      textColorToken,
      bgColorToken,
    },
    ref,
  ) => {
    return (
      <>
        <Paragraph
          tag="span"
          type="lead"
          className={clsx(
            'inline-block w-full pb-5 form-text',
            hidden && 'hidden',
            !selectedImage && 'sm:mt-10 xl:mt-0',
            !textColorToken && 'text-darkgray',
          )}
        >
          {label}
        </Paragraph>
        <button
          onClick={onButtonClick}
          className={clsx(
            'px-5 font-neutral tracking-wider text-sm w-full border rounded-2xl flex items-center justify-between h-12',
            {
              'text-darkgray border-grayBorder': !textColorToken,
            },
          )}
        >
          <SelectedItem selectedItem={selectedItem} selectedImage={selectedImage} />
          <>{showDropDown ? <SelectBoxArrowUp /> : <SelectBoxArrowDown />}</>
        </button>
        <ul
          className={clsx(
            'absolute z-10 rounded-2xl w-full border  p-0  max-h-countrySelectorHeight overflow-auto mt-2.5 no-scrollbar',
            !showDropDown && 'hidden',
            !textColorToken && 'bg-light-brown border-grayBorder',
          )}
          style={{ backgroundColor: bgColorToken }}
        >
          {data?.map((item: ICountryItem | ILanguageItem) => (
            <div
              ref={ref}
              key={`show-dropdown-${item.id}`}
              className={showDropDown ? '' : 'hidden'}
            >
              <li
                className={clsx(
                  'border-b m-0 min-h-[60px] first:rounded-t-2xl last:rounded-b-2xl last:border-b-0',
                  {
                    'border-grayBorder hover:bg-dark-brown hover:border-gray-500': !textColorToken,
                  },
                )}
                key={`${item?.id}`}
              >
                {selectedImage ? (
                  <CountryItem country={item as ICountryItem} updateCountry={update} />
                ) : (
                  <LanguageItem language={item as ILanguageItem} updateLanguage={update} />
                )}
              </li>
            </div>
          ))}
        </ul>
      </>
    );
  },
);

export default DropDown;
