import { Text as Paragraph } from '../../Typography';
import { NextLink } from '../../NextLink';

const Button = ({ label, link }: { link?: string; label?: string }) => {
  return (
    <NextLink
      href={link}
      className="border border-grayBorder rounded-3xl px-8 md:py-4 sm:py-5 md:mt-5 sm:mt-10 w-auto inline-block"
    >
      <Paragraph tag="span" type="lead">
        {label}
      </Paragraph>
    </NextLink>
  );
};

export default Button;
