import { Accordion } from '../Accordion';
import { Title } from '../Typography';
import { Grid, GridRow, GridItem } from '../Grid';
import type { FooterNav } from '../../types/Footer';
import FadeInSection from '../FadeInSection';
import clsx from 'clsx';
import ListTemplate from './components/ListTemplate';
import SocialMediaLinks from './components/SocialMediaLinks';
import getMobileNavData from './components/getMobileNavData';
import { FooterNavType, IFooterImage } from './types';
import Seals from './components/Seals';
import Legal from './components/Legal';
import AppStore from './components/AppStore';
import FooterImage from './components/FooterImage';
import { FooterNavigation } from '../../types/Footer';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';

interface IFooterProps {
  footerData: FooterNav;
  countrySelectorData: any;
  isNewsletter?: boolean;
  trackingEvents?: {
    about: (target: string) => void;
    social: (target: string) => void;
    more: (target: string) => void;
    appStore: (device: string) => void;
  };
  footerImage?: IFooterImage;
  fillColorToken?: string;
  fillColorClass?: string;
  textColorToken?: string;
}

export const Footer = ({
  footerData,
  countrySelectorData,
  isNewsletter = false,
  trackingEvents,
  footerImage,
  fillColorToken,
  fillColorClass,
  textColorToken,
}: IFooterProps) => {
  const { pathTranslations } = useGlobalContext();
  // Check the type of link section before assigning a tracking event
  const onClickHandler = (target: string, linksTitle?: string) => {
    if (linksTitle?.toLowerCase().includes('about')) {
      if (trackingEvents?.about) trackingEvents?.about(target);
    }
    if (linksTitle?.toLowerCase().includes('social')) {
      if (trackingEvents?.social) trackingEvents?.social(target);
    }
    if (linksTitle?.toLowerCase().includes('more')) {
      if (trackingEvents?.more) trackingEvents?.more(target);
    }
  };
  const footerNav = footerData?.footerNavigations;
  const privacySettingsTitle = footerData?.privacySettingsTitle;
  const download = footerData?.downloadApps?.[0];
  const socialMedia = footerData?.socialMediaListComponents;
  const seals = footerData?.footerSeals;
  const legal = footerNav?.find((item: any) => item.footerNavType === 'LegalSection');
  const mainNav = footerNav?.filter(
    (mainitem: FooterNavigation) => mainitem.footerNavType === 'MainNav',
  );
  const socialMediaLinks = () => {
    return (
      <SocialMediaLinks
        socialMedia={socialMedia}
        onClickHandler={onClickHandler}
        backgroundColorToken={textColorToken}
      />
    );
  };

  return (
    <>
      {footerImage && (
        <div className="lg:mb-[-200px] md:mb-[-20vw] sm:mb-[-15vw] mt-20 print:hidden">
          <FooterImage id="FooterImage" image={footerImage} />
        </div>
      )}
      <FadeInSection delay="50ms" isVisible>
        <footer
          className={clsx('relative mt-20 print:hidden', fillColorClass)}
          style={{ backgroundColor: fillColorToken, color: textColorToken }}
        >
          <svg
            className="absolute left-0 mt-[-4.75rem]"
            width="100%"
            height="80"
            viewBox="0 0 1290 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={fillColorClass}
              style={{ fill: fillColorToken }}
              d="M16 168C7.16344 168 0 160.837 0 152V43.5099C0 43.5099 296.521 -3.02334e-05 647.688 0C998.854 3.02336e-05 1290 43.51 1290 43.51V152C1290 160.837 1282.84 168 1274 168H16Z"
            />
          </svg>
          <div
            className={clsx(
              'pb-4 flex flex-grow border-b border-green',
              isNewsletter && 'md:pb-20 lg:pt-44 md:pt-36 sm:pt-24',
            )}
          >
            <Grid>
              <div className="block md:hidden">
                <Accordion
                  name=""
                  data={getMobileNavData({
                    textColorToken,
                    footerNav,
                    onClickHandler,
                    pathTranslations,
                    socialMediaLinks: socialMediaLinks(),
                  })}
                  collapsed
                />
              </div>
              <div className="hidden md:block">
                <GridRow columns={16}>
                  {mainNav?.map((linkSectionTitle: FooterNavType) => (
                    <GridItem colSpan={4} key={`title=${linkSectionTitle.id}`}>
                      <Title
                        tag="div"
                        type="xs"
                        className={clsx({
                          'text-gray-500': !textColorToken,
                        })}
                      >
                        {linkSectionTitle.cmsTitle}
                      </Title>
                    </GridItem>
                  ))}
                </GridRow>
                <GridRow columns={16}>
                  {mainNav?.map((footerLinks: FooterNavType) => (
                    <GridItem colSpan={4} key={`body=${footerLinks.id}`}>
                      {footerLinks?.socialMediaLink ? (
                        socialMediaLinks()
                      ) : (
                        <ul className="pl-0 m-0">
                          <ListTemplate
                            onClickHandler={onClickHandler}
                            flinks={footerLinks}
                            linksTitle={footerLinks?.cmsTitle || ''}
                          />
                        </ul>
                      )}
                    </GridItem>
                  ))}
                </GridRow>
              </div>
              <AppStore
                trackingEventsAppStore={trackingEvents?.appStore}
                countrySelectorData={countrySelectorData}
                download={download}
                textColorToken={textColorToken}
              />
              {!!seals?.length && <Seals seals={seals} textColorToken={textColorToken} />}
              {legal && (
                <Legal
                  legal={legal}
                  privacySettingsTitle={privacySettingsTitle}
                  textColorToken={textColorToken}
                />
              )}
            </Grid>
          </div>
        </footer>
      </FadeInSection>
    </>
  );
};
