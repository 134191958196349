import { ICountryItem } from '../types';
import React from 'react';
import NextImage from '../../NextImage';

const CountryItem = ({
  country,
  updateCountry,
}: {
  country: ICountryItem;
  updateCountry: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <button
      data-countrycode={country.countryCode}
      data-flag={country?.countryFlag?.asset?.[0]?.url || ''}
      onClick={updateCountry}
      id={country.id}
      className="hover:text-white block py-18 px-5 w-full font-neutral tracking-wider text-sm text-darkgray text-left"
    >
      {country?.countryFlag?.asset?.[0]?.url !== null && (
        <NextImage
          mimeType={String(country?.countryFlag?.asset?.[0]?.mimeType)}
          src={country?.countryFlag?.asset?.[0]?.url ?? ''}
          alt="Flag image country"
          className="align-middle mr-6 inline"
          width={18}
          height={18}
        />
      )}
      {country?.country}
    </button>
  );
};

export default CountryItem;
